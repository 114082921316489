module.exports = [
  {
    path: '/',
    name: 'home'
  },
  {
    path: '/contribute',
    name: 'contribute'
  },
  {
    path: '/collaborators',
    name: 'collaborators'
  },
  {
    path: '/feedback',
    name: 'feedback'
  }
];
